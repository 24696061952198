import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import '@cedcommerce/ounce-ui/dist/index.css';
import './index.css';

const App = lazy(() => import('./App'));

// import App from './App';

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store } from './Reducers';
import { PageLoader } from '@cedcommerce/ounce-ui';

export const StoreDispatcher = React.createContext(store.dispatch);

ReactDOM.render(
    <BrowserRouter basename="">
        <Provider store={store}>
            <StoreDispatcher.Provider value={store.dispatch}>
                <Suspense fallback={<PageLoader />}>
                    <App />
                </Suspense>
            </StoreDispatcher.Provider>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
